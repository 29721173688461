import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../../../../components/FormSection/FormSection';
import VehicleRunShowJobsSection from './VehicleRunShowJobsSection/VehicleRunShowJobsSection';
import Booking from '../../../../../../api/controllers/Job/Booking/Booking';
import IconButton from '../../../../../../common/button/IconButton/IconButton.jsx';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const VehicleRunShowJobs = ({ run, openSendDetailsModal }) => {
  const [bookings, setBookings] = useState([]);
  const canBeSentToFS =
    bookings.some(booking => booking.freightsoftReference) && run?.vehicle?.kind;

  useEffect(() => {
    Booking.all({ vehicleRunId: run.id }).then(res => setBookings(res.items));
  }, [run.id]);

  return (
    <div className="vrs-jobs tab-content">
      <FormSection
        title="Jobs"
        optionalElement={
          canBeSentToFS && (
            <IconButton
              text="Send details to FREIGHTsoft"
              className="vrs-jobs-send-to-fs"
              icon={faArrowUp}
              onClick={openSendDetailsModal}
              disabled={false}
            />
          )
        }
      >
        {bookings.length === 0 && (
          <div className="no-jobs-message">There is no jobs on this vehicle run</div>
        )}
        {bookings.map(job => (
          <VehicleRunShowJobsSection key={job.id} job={job} />
        ))}
      </FormSection>
    </div>
  );
};

VehicleRunShowJobs.propTypes = { run: PropTypes.object, openSendDetailsModal: PropTypes.func };

export default VehicleRunShowJobs;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from '../../../../common/button/IconButton/IconButton';
import useApi from '../../../../common/hooks/useApi/useApi';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Table from '../../../../components/Table/Table/Table';
import './VehicleIndex.scss';
import ErrorBoundary from '../../../../common/ErrorBoundary/ErrorBoundary';
import Vehicle from '../../../../api/controllers/Movement/Vehicle/Vehicle';
import CheckPermit from '../../../../common/CheckPermit/CheckPermit';
import VehicleRow from './subComponents/VehicleRow';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import Message from '../../../../common/layout/Message/Message';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import { useLocation, useNavigate } from 'react-router';

const headers = [
  { text: 'Reference', sortingAttribute: 'reference' },
  { text: 'Driver', className: 'tiny' },
  { text: 'Type', className: 'tiny' },
  { text: 'Fuel', className: 'tiny' },
  { text: 'MPG', sortingAttribute: 'milesPerGallon', type: 'number', className: 'tiny' },
  { text: 'KM', className: 'x-tiny' },
  { text: 'Capacity (kg)', sortingAttribute: 'weightLimitKg', type: 'number' },
  { text: 'Length (cm)', sortingAttribute: 'lengthCm', type: 'number' },
  { text: 'Width (cm)', sortingAttribute: 'widthCm', type: 'number' },
  { text: 'Height (cm)', sortingAttribute: 'heightCm', type: 'number' },
  { text: 'Base Cost (£)', sortingAttribute: 'baseCost', type: 'number' },
  { text: 'MSO', className: 'x-tiny' },
  { text: 'Archived', className: 'x-tiny' },
  { text: 'Actions' },
];

const VehicleIndex = ({ permissions }) => {
  const [open, setOpen] = useState();
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = useState(false);
  const { data, dataLoading, refresh } = useApi({
    call: Vehicle.all,
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.detail === 'Vehicle Deleted') {
      setSuccessMessage('Vehicle has successfully been deleted.');
      location.state.detail = undefined;
    }
  }, []);

  document.title = 'All Vehicles';

  const handleDelete = id => {
    setLoading(true);
    Vehicle.destroy(id).then(
      () => {
        setLoading(false);
        setOpen();
        refresh();
        setSuccessMessage('Vehicle has successfully been deleted.');
      },
      json => {
        setLoading(false);
        setError({ ...formatError(json), errorTitle: 'Delete Failed!' });
        setOpen();
      },
    );
  };
  return (
    <div className="all-vehicles">
      <ConfirmationModal
        question={'Are you sure you want to delete this vehicle?'}
        subText={open?.reference}
        confirmText={'Delete'}
        isOpen={!!open}
        handleConfirm={() => handleDelete(open?.id)}
        handleCancel={() => setOpen()}
        loading={loading}
      />
      <Breadcrumbs>
        <Link to={'/vehicles'}>Vehicles</Link>
      </Breadcrumbs>
      <Ribbon>
        <CheckPermit type={'movementsVehicles'} action={'create'} permissions={permissions}>
          <IconButton
            text={'Add Vehicle'}
            icon={faPlus}
            onClick={() => navigate('/vehicles/new')}
          />
        </CheckPermit>
      </Ribbon>
      <div className="page-content">
        <Message text={error} type={'error'} visible={!!error} onClick={() => setError()} />
        <Message
          text={successMessage}
          type={'success'}
          onClick={() => setSuccessMessage()}
          visible={!!successMessage}
          onClose={setSuccessMessage}
        />
        {data && !dataLoading && (
          <ErrorBoundary>
            <Table
              title={'Vehicles'}
              headers={headers}
              data={data}
              onDrawRow={(item, i) => (
                <VehicleRow
                  key={i}
                  vehicle={item}
                  id={i}
                  handleDelete={setOpen}
                  permissions={permissions}
                />
              )}
              searchable={['reference']}
            />
          </ErrorBoundary>
        )}
        {dataLoading && <LoadingSpinner />}
      </div>
    </div>
  );
};

VehicleIndex.propTypes = {
  permissions: PropTypes.object,
};

export default VehicleIndex;

import React from 'react';
import { Link } from 'react-router-dom';
import DateHelper from '../../../../utils/DateHelper/DateHelper';

// Takes a Report row, a column and an index from the .map()
export const formatCell = (row, key, i) => {
  const val = row[key]; // Get our value from the row e.g. 10.0 for a currency
  if (val === null) return <td key={i}></td>; // If nothing is there, return nothing
  if (_isDate(val)) return <td key={i}>{DateHelper.dateOnly(val)}</td>;
  if (typeof val === 'object') return <td key={i}>{_getModelLink(val, row)}</td>;
  return <td key={i}>{val}</td>;
};

// Takes Report row and a column, returns a formatted string
export const formatVal = (row, key) => {
  const val = row[key]; // Get our value from the row e.g. 10.0 for a currency
  if (val === null) return ''; // If nothing is there, return nothing
  if (_isDate(val)) return DateHelper.dateOnly(val);
  if (typeof val === 'object') return val.text;
  return val;
};

// Returns either a link or plaintext
const _getModelLink = (val, row) => {
  const to =
    val.modelName == 'Financials::Document'
      ? getFinancialDocumentLink(row.Document)
      : modelList[val.modelName];

  // Email will only be present on models requiring a mailto
  if (val.email) return <a href={`mailto:${val.email}`}>{val.text}</a>;
  if (!to && !val.email) return val.text; // Failsafe

  return (
    <Link to={`${to}${val.id}`} target="_blank">
      {val.text}
    </Link>
  );
};

// A helper function to determine if the returned value is a date
const _isDate = val => typeof val === 'string' && val.match(/(\d\d\d\d-\d\d-\d\d)/g);

// This will return the link for the modelName provided
export const modelList = {
  'Jobs::Booking': '/jobs/bookings/',
  'Companies::Entity': '/companies/entities/',
  'Movements::VehicleRun': '/movements/runs/',
};

// This will return the correct link for the financial document provided
export const getFinancialDocumentLink = document => {
  if (document.bulkInvoicing) {
    return '/financials/bulk_sales_invoices/';
  }
  if (document.purchase) {
    if (document.credit) {
      return '/financials/purchase_credit_notes/';
    } else {
      return '/financials/purchase_invoices/';
    }
  } else {
    if (document.credit) {
      return '/financials/sales_credit_notes/';
    } else {
      return '/financials/sales_invoices/';
    }
  }
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal/Modal';
import './SendVehicleDetails.scss';
import FormSection from '../../FormSection/FormSection';
import Message from '../../../common/layout/Message/Message';
import VehicleRun from '../../../api/controllers/Movement/VehicleRun/VehicleRun';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faBoxesStacked, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const SendVehicleDetails = ({ handleClose, fromFinalize = false, run, handleError }) => {
  const [modalMessage, setModalMessage] = useState();
  const [innerMessage, setInnerMessage] = useState();
  const [render, setRender] = useState();
  const [bookings, setBookings] = useState();
  const canBeSentToFS =
    run?.bookings?.some(booking => booking?.freightsoftReference) && run?.vehicle?.kind;

  const sendDetails = () => {
    VehicleRun.sendToFREIGHTsoft(run.id)
      .then(res => {
        let bookings = run.bookings;
        res.bookings.map(fromFSBooking => {
          const jobIndex = bookings.findIndex(
            booking => booking.jobReference == fromFSBooking.jobReference,
          );
          bookings[jobIndex] = {
            ...bookings[jobIndex],
            error: fromFSBooking.apiResponse.error,
          };
        });
        setBookings(bookings);
        if (!fromFinalize) {
          setModalMessage({ type: 'success', text: 'Vehicle details sent to FREIGHTsoft' });
        }
        setRender(true);
      })
      .catch(e => {
        if (fromFinalize) {
          setInnerMessage({ type: 'error', text: e.error || e.errors });
          setRender(true);
        } else {
          handleError({ type: 'error', text: e.error || e.errors });
          handleClose();
        }
      });
  };

  useEffect(() => {
    if (fromFinalize) {
      setModalMessage({ type: 'success', text: 'Vehicle Run has successfully been finalised' });
    }
    if (canBeSentToFS) {
      sendDetails();
    } else {
      setBookings(run.bookings);
      setRender(true);
    }
  }, []);

  if (!render) return;
  return (
    <Modal isOpen handleClose={handleClose}>
      <FormSection
        title={fromFinalize ? 'Finalise' : 'Send details to FREIGHTsoft - Results'}
        extraClassName={'svd-modal'}
      >
        {modalMessage && (
          <Message type={modalMessage.type} text={modalMessage.text} visible dismissable={false} />
        )}
        <FormSection
          title={fromFinalize ? 'Details sent to FREIGHTsoft' : 'Results'}
          extraClassName={'svd-results'}
        >
          {innerMessage && <div className="svd-error">{innerMessage.text}</div>}
          {bookings && (
            <div className="svd-results-table">
              <div className="svd-results-row">
                <div className="svd-header"></div>
                <div className="svd-header job-number">Job Number</div>
                <div className="svd-header fs-ref">FREIGHTsoft Ref.</div>
                <div className="svd-header details">Details</div>
              </div>
              {bookings?.map(booking => {
                return (
                  <div className="svd-results-row result" key={booking.id}>
                    <FontAwesomeIcon icon={faBoxesStacked} className="svd-cell" />
                    <div className="svd-cell">
                      <Link to={`/jobs/bookings/${booking.id}`} target={'_blank'}>
                        {booking.jobReference}
                      </Link>
                    </div>
                    <div className={`svd-cell ${!booking.freightsoftReference && 'greyed-out'}`}>
                      {booking.freightsoftReference ? (
                        <a
                          href={`${process.env.REACT_APP_FREIGHTSOFT_URL}/jobs/${booking.freightsoftReference}`}
                          target={'_blank'}
                          rel={'noreferrer'}
                        >
                          {booking.customerReference}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </div>
                    <div
                      className={`svd-cell ${booking.error && 'error'} ${
                        (!booking.freightsoftReference || !canBeSentToFS) && 'greyed-out'
                      }`}
                    >
                      {booking.error
                        ? booking.error
                        : booking.freightsoftReference && canBeSentToFS
                        ? 'Success'
                        : 'N/A'}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </FormSection>
        <div className="svd-buttons">
          <IconButton text="Done" className="svd-done" icon={faCheck} onClick={handleClose} />
        </div>
      </FormSection>
    </Modal>
  );
};

SendVehicleDetails.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  fromFinalize: PropTypes.bool,
  run: PropTypes.object,
};

export default SendVehicleDetails;

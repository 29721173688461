import apiCall from '../../../apiCall/apiCall';
import buildGetQuery from '../../../helpers/buildGetQuery/buildGetQuery';

class VehicleRun {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'movements/vehicle_runs', data);
  }

  static show(id) {
    return apiCall('GET', `movements/vehicle_runs/${id}`);
  }

  static create(params) {
    const data = { vehicle_run: params };
    return apiCall('POST', 'movements/vehicle_runs', data);
  }

  static update(id, params) {
    const data = { vehicle_run: params };
    return apiCall('PATCH', `movements/vehicle_runs/${id}`, data);
  }

  static destroy(id) {
    return apiCall('DELETE', `movements/vehicle_runs/${id}`);
  }

  static sendToFREIGHTsoft(id) {
    return apiCall('POST', `/movements/vehicle_runs/${id}/push_to_freightsoft`);
  }
}

export default VehicleRun;
